import { useEffect, useState } from "react";
import React from "react";
import Navbar from "../Components/Navbar";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { useEstateCode } from "../Components/EstateCodeContext";
import { handleApiError } from "../Components/apiErrorHandling";
import axios from "axios";
import BasicRangeShortcuts from "../Pages/Demo";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const getApiUrl = (path) => {
  const hostname = window.location.hostname;
  if (hostname.includes("testgrouple.com") || hostname.includes("localhost")) {
    return `https://server.testgrouple.com${path}`;
  }
  if (hostname.includes("grouple.tech")) {
    return `https://production.grouple.tech${path}`;
  }
  throw new Error("Unknown hostname");
};

const Analytics = () => {
  const { estateCode } = useEstateCode();
  const [isCategoryOpen, setIsCategoryOpen] = useState(false);
  const [stats, setStats] = useState(null);
  const [outletCurrency, setOutletCurrency] = useState("");

  // Date logic
  const formatDateString = (date) => {
    return date ? new Date(date).toISOString().split("T")[0] : "";
  };
  const currentDate = new Date();
  const defaultStartDate = new Date(currentDate);
  const defaultEndDate = new Date(currentDate);
  defaultEndDate.setDate(currentDate.getDate() + 1);
  defaultStartDate.setDate(currentDate.getDate());
  const [dateRange, setDateRange] = useState([
    defaultStartDate,
    defaultEndDate,
  ]);

  const [dateRangeValue, setDateRangeValue] = useState(null);

  const [startDate, setStartDate] = useState(
    formatDateString(defaultStartDate)
  );
  const [endDate, setEndDate] = useState(formatDateString(defaultEndDate));

  const handleChange = (value) => {
    setDateRangeValue(value || [defaultStartDate, defaultEndDate]);
  };

  useEffect(() => {
    setDateRangeValue([defaultStartDate, defaultEndDate]);
  }, []);

  useEffect(() => {
    if (!dateRangeValue) {
      setDateRange([defaultStartDate, defaultEndDate]);
    } else {
      setDateRange(dateRangeValue);
    }
  }, [dateRangeValue]);

  useEffect(() => {
    if (dateRange.length === 2) {
      setStartDate(formatDateString(dateRange[0]));
      setEndDate(formatDateString(dateRange[1]));
    }
  }, [dateRange]);

  console.log(startDate, endDate);

  const fetchAnalyticsStats = async () => {
    try {
      const url = getApiUrl(
        `/api/v1/merchant/analytics/viewKeyMetrics/${estateCode}?startDate=${startDate}&endDate=${endDate}`
      );
      const response = await axios.get(url, { withCredentials: true });

      console.log(response);
    } catch (error) {
      handleApiError(error);
    }
  };

  useEffect(() => {
    fetchAnalyticsStats();
  }, [estateCode, startDate, endDate]);

  return (
    <>
      <Navbar />
      <div class="w-full pl-5 pr-5 pt-4 pb-2 items-center justify-between inline-flex">
        <div class="text-neutral-800 text-2xl font-medium font-['Inter']">
          Analytics
        </div>
         
      </div>

      <div className="bg-lightBlue py-6 px-5 flex flex-col">
        <div className="flex gap-8 w-full justify-end">
          {" "}
          <div class="h-[34px] px-3 py-2.5 bg-white rounded border border-[#d8d8dc] justify-start items-center gap-1.5 inline-flex">
            <div class="text-[#242426] text-sm font-normal font-['Inter']">
              Venue
            </div>
            <div class="w-3.5 h-3.5 relative"></div>
          </div>
          <BasicRangeShortcuts
            dateRangeValue={dateRangeValue}
            onChange={handleChange}
          />
        </div>

        <div class="text-[#242426] text-lg font-medium font-['Inter'] mb-[1.5rem]">
          Booking Status
        </div>

        <div className="w-full mt-5 flex gap-4">
          <div class="w-[24%] px-3 py-[20px] bg-white rounded-lg border border-[#f6f6f6] flex-col justify-start items-start gap-[18px] inline-flex">
            <div class="self-stretch flex-col justify-start items-start gap-3 flex">
              <div class="flex-col justify-start items-start gap-2 flex">
                <div class="justify-start items-center inline-flex">
                  <div class="text-[#2f2f2f] text-base font-normal font-['Inter']">
                    Total Booking
                  </div>
                </div>
                <div class="text-black text-[28px] font-bold font-['Inter']">
                  789
                </div>
              </div>
            </div>
            <div class="w-[236px] justify-start items-center gap-2 inline-flex">
              <div class="px-2.5 py-1 bg-[#e4f8eb] rounded-3xl justify-center items-center gap-1 flex">
                <div class="w-3 h-3 relative"></div>
                <div class="text-[#2eb265] text-xs font-medium font-['Inter']">
                  40.5%{" "}
                </div>
              </div>
            </div>
          </div>
          <div class="w-[24%] px-3 py-[20px] bg-white rounded-lg border border-[#f6f6f6] flex-col justify-start items-start gap-[18px] inline-flex">
            <div class="self-stretch flex-col justify-start items-start gap-3 flex">
              <div class="flex-col justify-start items-start gap-2 flex">
                <div class=" justify-start items-center inline-flex">
                  <div class="text-[#2f2f2f] text-base font-normal font-['Inter']">
                    Confirmed Booking
                  </div>
                </div>
                <div class="text-black text-[28px] font-bold font-['Inter']">
                  789
                </div>
              </div>
            </div>
            <div class="w-[236px] justify-start items-center gap-2 inline-flex">
              <div class="px-2.5 py-1 bg-[#e4f8eb] rounded-3xl justify-center items-center gap-1 flex">
                <div class="w-3 h-3 relative"></div>
                <div class="text-[#2eb265] text-xs font-medium font-['Inter']">
                  40.5%{" "}
                </div>
              </div>
            </div>
          </div>
          <div class="w-[24%] px-3 py-[20px] bg-white rounded-lg border border-[#f6f6f6] flex-col justify-start items-start gap-[18px] inline-flex">
            <div class="self-stretch flex-col justify-start items-start gap-3 flex">
              <div class="flex-col justify-start items-start gap-2 flex">
                <div class="justify-start items-center inline-flex">
                  <div class="text-[#2f2f2f] text-base font-normal font-['Inter']">
                    Pending Booking
                  </div>
                </div>
                <div class="text-black text-[28px] font-bold font-['Inter']">
                  789
                </div>
              </div>
            </div>
            <div class="w-[236px] justify-start items-center gap-2 inline-flex">
              <div class="px-2.5 py-1 bg-[#e4f8eb] rounded-3xl justify-center items-center gap-1 flex">
                <div class="w-3 h-3 relative"></div>
                <div class="text-[#2eb265] text-xs font-medium font-['Inter']">
                  40.5%{" "}
                </div>
              </div>
            </div>
          </div>
          <div class="w-[24%] px-3 py-[20px] bg-white rounded-lg border border-[#f6f6f6] flex-col gap-[18px] inline-flex">
            <div class="self-stretch flex-col gap-3 flex">
              <div class="flex-col justify-start items-start gap-2 flex">
                <div class="justify-start items-center inline-flex">
                  <div class="text-[#2f2f2f] text-base font-normal font-['Inter']">
                    Cancelled Booking
                  </div>
                </div>
                <div class="text-black text-[28px] font-bold font-['Inter']">
                  789
                </div>
              </div>
            </div>
            <div class="w-[236px] justify-start items-center gap-2 inline-flex">
              <div class="px-2.5 py-1 bg-[#e4f8eb] rounded-3xl justify-center items-center gap-1 flex">
                <div class="w-3 h-3 relative"></div>
                <div class="text-[#2eb265] text-xs font-medium font-['Inter']">
                  40.5%{" "}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* charts */}
        <div className="w-[71rem] h-[27rem] bg-white rounded-2xl mt-5"></div>

        <div className="w-full flex gap-4 mt-5 ">
          <div className="w-[75%] px-4 py-4 min-h-[20rem] bg-white rounded-xl">
            Package analysis
          </div>
          <div className="w-[25%] px-4 py-4 bg-white rounded-xl">
            Key metrics
          </div>
        </div>
      </div>
    </>
  );
};

export default Analytics;

// switch (response.status) {
//   case 200:
//     const responseData = response.data.data;
//     setOutletCurrency(responseData.currency);
//     const updatedStats = [
//       {
//         id: 1,
//         name: "Total Revenue",
//         stat: responseData.dashboardRevenueAnalysis
//           ? responseData.dashboardRevenueAnalysis.current_month_revenue
//           : "1",
//         // icon: UsersIcon,
//         change: responseData.dashboardRevenueAnalysis
//           ? responseData.dashboardRevenueAnalysis
//               .revenue_change_percentage + "%"
//           : "1%",
//         changeType: responseData.dashboardRevenueAnalysis
//           ? responseData.dashboardRevenueAnalysis
//               .revenue_change_percentage >= 0
//             ? "increase"
//             : "decrease"
//           : "increase",
//       },
//       {
//         id: 2,
//         name: "Total Bookings",
//         stat: responseData.dashboardBookingAnalysis
//           ? responseData.dashboardBookingAnalysis.current_month_booking
//           : "1",
//         // icon: EnvelopeOpenIcon,
//         change: responseData.dashboardBookingAnalysis
//           ? responseData.dashboardBookingAnalysis
//               .booking_change_percentage + "%"
//           : "1%",
//         changeType: responseData.dashboardBookingAnalysis
//           ? responseData.dashboardBookingAnalysis
//               .booking_change_percentage >= 0
//             ? "increase"
//             : "decrease"
//           : "increase",
//       },
//       {
//         id: 3,
//         name: "Average per spent",
//         stat: responseData.dashboardAveragePerSpent
//           ? responseData.dashboardAveragePerSpent
//               .current_month_avg_per_spent
//           : "1",
//         // icon: CursorArrowRaysIcon,
//         change: responseData.dashboardAveragePerSpent
//           ? responseData.dashboardAveragePerSpent
//               .avg_per_spent_change_percentage + "%"
//           : "1%",
//         changeType: responseData.dashboardAveragePerSpent
//           ? responseData.dashboardAveragePerSpent
//               .avg_per_spent_change_percentage >= 0
//             ? "increase"
//             : "decrease"
//           : "increase",
//       },
//       {
//         id: 4,
//         name: "Average group size",
//         stat: responseData.dashboardAverageGroupSize
//           ? responseData.dashboardAverageGroupSize
//               .current_month_avg_group_size
//           : "1",
//         // icon: CursorArrowRaysIcon,
//         change: responseData.dashboardAverageGroupSize
//           ? responseData.dashboardAverageGroupSize
//               .avg_group_size_change_percentage + "%"
//           : "1%",
//         changeType: responseData.dashboardAverageGroupSize
//           ? responseData.dashboardAverageGroupSize
//               .avg_group_size_change_percentage >= 0
//             ? "increase"
//             : "decrease"
//           : "increase",
//       },
//     ];
//     setStats(updatedStats);
//     break;
//   case 204:
//     setStats([]);
//     break;
//   default:
//     console.warn(`Unexpected status code: ${response.status}`);
//     setStats(null);
//     break;
// }
