import React, { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import CountryFlag from "react-country-flag";
import { Select } from "antd";
import axios from "axios";
import { handleApiError } from "./apiErrorHandling";

const getApiUrl = (path) => {
  const hostname = window.location.hostname;
  if (hostname.includes("testgrouple.com") || hostname.includes("localhost")) {
    return `https://server.testgrouple.com${path}`;
  }
  if (hostname.includes("grouple.tech")) {
    return `https://production.grouple.tech${path}`;
  }
  throw new Error("Unknown hostname");
};

export default function SlideOverTwo({
  member,
  onClose,
  isSlideOverInviteOpen,
  employeeCode,
  fetchMembers,
}) {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    designation: "Admin",
    countryCode: "",
    role_id: 2,
    outlets: [],
  });

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isSpoc, setIsSpoc] = useState(0);
  const [memberRole, setMemberRole] = useState(null);

  useEffect(() => {
    setOpen(true);

    if (member && member.length > 0) {
      const {
        outlets,
        merchant_user_is_spoc,
        merchant_user_countryCode,
        merchant_user_phoneNumber,
        merchant_user_mobile,
        merchant_user_firstName,
        merchant_user_lastName,
        merchant_user_email,
      } = member[0];

      let countryCode = merchant_user_countryCode ?? "+44";
      if (!countryCode.startsWith("+")) {
        countryCode = "+" + countryCode;
      }
      const countryCodePlain = countryCode.replace("+", "");

      let phoneNumberWithoutCountryCode = merchant_user_mobile;
      if (phoneNumberWithoutCountryCode.startsWith(countryCodePlain)) {
        phoneNumberWithoutCountryCode = phoneNumberWithoutCountryCode.slice(
          countryCodePlain.length
        );
      }

      const initialRoleId =
        outlets && outlets.length > 0 && outlets[0].role_id === 1 ? 1 : 2;
      const initialDesignation = initialRoleId === 1 ? "Owner" : "Admin";

      setFormData({
        firstName: merchant_user_firstName ?? "",
        lastName: merchant_user_lastName ?? "",
        email: merchant_user_email ?? "",
        phoneNumber: phoneNumberWithoutCountryCode,
        countryCode: countryCode,
        outlets: outlets ? outlets.map((outlet) => outlet.estatecode) : [],
        role_id: initialRoleId,
        designation: initialDesignation,
      });
      setIsSpoc(merchant_user_is_spoc || 0);
    } else {
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        designation: "Admin",
        countryCode: "+44",
        role_id: 2,
        outlets: [],
      });
    }
  }, [member]);

  const handleClose = () => {
    setOpen(false);
    onClose();
    resetFormData();
  };

  const resetFormData = () => {
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      designation: "Admin",
      countryCode: "+44",
      role_id: 2,
      outlets: [],
    });
    setIsSpoc(0);
  };

  const handleRoleChange = (e, roleId) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      designation: value,
      role_id: roleId,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
      role_id: prevData.role_id || 2,
      countryCode: prevData.countryCode || "+44",
      designation: prevData.designation || "Admin",
    }));
  };

  const handleSendInvite = async (e) => {
    e.preventDefault();
    let countryCode = formData.countryCode;
    if (!countryCode.startsWith("+")) {
      countryCode = "+" + countryCode;
    }
    const phoneNumber = formData.phoneNumber;
    const countryCodePlain = countryCode.replace("+", "");

    let phoneNumberWithoutCountryCode;
    if (phoneNumber.startsWith(countryCodePlain)) {
      phoneNumberWithoutCountryCode = phoneNumber.slice(
        countryCodePlain.length
      );
    } else {
      phoneNumberWithoutCountryCode = phoneNumber;
    }
    try {
      const url = getApiUrl("/api/v2/merchant/roles/sendInvitation");
      const response = await axios.post(
        url,
        {
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          designation: formData.designation,
          countryCode: countryCode,
          phoneNumber: phoneNumberWithoutCountryCode,
          role_id: formData.role_id,
          outlets: formData.outlets,
          isSPOC: isSpoc,
        },
        { withCredentials: true }
      );
      setSuccessMessage("Invitation sent successfully");
      setErrorMessage("");
      handleClose();
      fetchMembers();
    } catch (error) {
      setErrorMessage("Error sending invitation");
      setSuccessMessage("");
      console.error("Error sending invite:", error);
    }
  };

  const handleEditPermission = async (e) => {
    e.preventDefault();

    let countryCode2 = formData.countryCode;
    if (!countryCode2.startsWith("+")) {
      countryCode2 = "+" + countryCode2;
    }
    const phoneNumber2 = formData.phoneNumber;
    const countryCodePlain2 = countryCode2.replace("+", "");

    let phoneNumberWithoutCountryCode2;
    if (phoneNumber2.startsWith(countryCodePlain2)) {
      phoneNumberWithoutCountryCode2 = phoneNumber2.slice(
        countryCodePlain2.length
      );
    } else {
      phoneNumberWithoutCountryCode2 = phoneNumber2;
    }
    try {
      const url = getApiUrl(
        `/api/v2/merchant/roles/editPermission/${employeeCode}`
      );
      const response = await axios.post(
        url,
        {
          firstName: formData.firstName,
          lastName: formData.lastName,
          employeeCode: employeeCode,
          designation: formData.designation || "Admin",
          countryCode: countryCode2,
          phoneNumber: phoneNumberWithoutCountryCode2,
          role_id: formData.role_id,
          outlets: formData.outlets,
          isSPOC: isSpoc,
        },
        { withCredentials: true }
      );
      setSuccessMessage("Permission edited successfully");
      setErrorMessage("");
      handleClose();
      fetchMembers();
    } catch (error) {
      setErrorMessage("Error editing permission");
      setSuccessMessage("");
      console.error("Error editing permission:", error);
    }
  };

  const [outletOptions, setOutletOptions] = useState([]);

  const fetchUserEstates = async () => {
    try {
      const url = getApiUrl("/api/v2/outlet/getUserEstate");
      const estatesResponse = await axios.get(url, {
        withCredentials: true,
      });

      if (estatesResponse.status === 200) {
        const estates = estatesResponse.data.data;
        const updatedOutletOptions = estates.map((estate) => ({
          value: estate.estatecode,
          label: estate.estate_name,
        }));
        setOutletOptions(updatedOutletOptions);
      }
    } catch (error) {
      handleApiError(error);
    }
  };

  useEffect(() => {
    fetchUserEstates();
  }, []);

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={handleClose}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <form className="flex h-full flex-col overflow-y-scroll bg-white">
                      <div className="flex-1">
                        {/* {/ Header /} */}
                        <div className="px-4 py-4 sm:px-6 border-b">
                          <div className="flex items-start justify-between space-x-3">
                            <div className="space-y-1">
                              <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                                Invite your team
                              </Dialog.Title>
                            </div>
                            <div className="flex h-7 items-center">
                              <button
                                type="button"
                                className="relative text-gray-400 hover:text-gray-500"
                                onClick={handleClose}
                              >
                                <span className="absolute -inset-2.5" />
                                <span className="sr-only">Close panel</span>
                                <XMarkIcon
                                  className="h-6 w-6"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                          </div>
                        </div>

                        {/* {/ form /} */}

                        <div className="mt-10 grid gap-x-6 gap-y-8 sm:grid-cols-6 px-4">
                          <div className="sm:col-span-3">
                            <label
                              htmlFor="first-name"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              First name
                            </label>
                            <div className="mt-2">
                              <input
                                type="text"
                                name="firstName"
                                id="first-name"
                                autoComplete="given-name"
                                value={formData.firstName}
                                onChange={handleChange}
                                className="block w-full rounded-md border-0 py-2.5 px-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>

                          <div className="sm:col-span-3">
                            <label
                              htmlFor="last-name"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              Last name
                            </label>
                            <div className="mt-2">
                              <input
                                type="text"
                                name="lastName"
                                id="last-name"
                                autoComplete="family-name"
                                value={formData.lastName}
                                onChange={handleChange}
                                className="block w-full rounded-md border-0 py-2.5 px-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>

                          {!isSlideOverInviteOpen && (
                            <div className="sm:col-span-4 w-[26rem]">
                              <label
                                htmlFor="email"
                                className="block text-sm font-medium leading-6 text-gray-900"
                              >
                                Email address
                              </label>
                              <div className="mt-2">
                                <input
                                  id="email"
                                  name="email"
                                  type="email"
                                  autoComplete="email"
                                  value={formData.email}
                                  readOnly
                                  onChange={handleChange}
                                  className="block w-full rounded-md cursor-not-allowed border-0 py-2.5 px-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          )}
                          {isSlideOverInviteOpen && (
                            <div className="sm:col-span-6 flex gap-2 items-center ">
                              <div className="w-full mt-4  sm:mt-0">
                                <label
                                  htmlFor="outlet"
                                  className="block text-sm font-medium leading-6 pb-2 text-gray-900"
                                >
                                  Email
                                </label>

                                {/* {/ email /}  */}
                                <div>
                                  <div>
                                    <input
                                      id="email"
                                      name="email"
                                      type="email"
                                      autoComplete="email"
                                      value={formData.email}
                                      onChange={handleChange}
                                      className="block w-full rounded-md border-0 py-2.5 px-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                          <div className="sm:col-span-4 w-[26rem] parentPhone">
                            <label
                              htmlFor="email"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              <span className="text-sm font-medium leading-6 text-gray-900">
                                Phone number{" "}
                              </span>
                              <span className="text-[#6f6f6f] text-sm font-normal font-['Inter']">
                                (Optional)
                              </span>
                            </label>

                            <div className="mt-1 inner-input">
                              <PhoneInput
                                country={"gb"}
                                inputStyle={{
                                  width: "100%",
                                  height: "3rem",
                                  borderRadius: "0.5rem",
                                  border: "1px solid #a6a6a6",
                                }}
                                dropdownStyle={{
                                  padding: "1rem",
                                  height: "80rem",
                                  width: "35rem",
                                  borderRadius: "0.5rem",
                                }}
                                name="phoneNumber"
                                value={formData.phoneNumber}
                                onChange={(value, country) => {
                                  const countryCode = country
                                    ? `+${country.dialCode}`
                                    : "+44";
                                  const localNumber = value.replace(
                                    countryCode,
                                    ""
                                  );
                                  setFormData({
                                    ...formData,
                                    countryCode,
                                    phoneNumber: localNumber,
                                  });
                                }}
                                maxLength={10}
                                buttonComponent={
                                  <CountryFlag
                                    countryCode={formData.countryCode}
                                    svg
                                  />
                                }
                              />
                            </div>
                          </div>

                          {isSlideOverInviteOpen && (
                            <div className="sm:col-span-6 flex gap-2 items-center">
                              <div>
                                <label
                                  htmlFor="designation"
                                  className="block text-sm font-medium leading-6 pb-2 text-gray-900"
                                >
                                  Select Permission Level
                                </label>
                                <div className="flex flex-col gap-2">
                                  <label className="p-3.5 bg-white rounded-lg border border-[#c5c5c5] flex-col justify-start items-start gap-2.5 inline-flex">
                                    <div className="self-stretch justify-start items-start gap-3 inline-flex">
                                      <input
                                        type="radio"
                                        name="designation"
                                        value="Admin"
                                        checked={
                                          formData.designation === "Admin"
                                        }
                                        onChange={(e) => handleRoleChange(e, 2)}
                                        className="form-radio mt-[0.36rem]"
                                      />
                                      <div className="flex-col justify-start items-start gap-2 inline-flex">
                                        <div className="self-stretch text-[#242426] text-base font-medium font-['Inter']">
                                          Admin
                                        </div>
                                        <div className="text-[#7c7c80] text-sm font-normal font-['Inter']">
                                          Full access to all features, including
                                          user management, analytics, payments,
                                          and platform settings.
                                        </div>
                                      </div>
                                    </div>
                                  </label>

                                  <label className="p-3.5 bg-white rounded-lg border border-[#c5c5c5] flex-col justify-start items-start gap-2.5 inline-flex">
                                    <div className="self-stretch justify-start items-start gap-3 inline-flex">
                                      <input
                                        type="radio"
                                        name="designation"
                                        value="Manager"
                                        checked={
                                          formData.designation === "Manager"
                                        }
                                        onChange={(e) => handleRoleChange(e, 3)}
                                        className="form-radio mt-[0.36rem]"
                                      />
                                      <div className="flex-col justify-start items-start gap-2 inline-flex">
                                        <div className="self-stretch text-[#242426] text-base font-medium font-['Inter']">
                                          Manager
                                        </div>
                                        <div className="text-[#7c7c80] text-sm font-normal font-['Inter']">
                                          Manage assigned outlets, approve
                                          bookings, edit details, and view
                                          performance metrics.
                                        </div>
                                      </div>
                                    </div>
                                  </label>

                                  <label className="p-3.5 bg-white rounded-lg border border-[#c5c5c5] flex-col justify-start items-start gap-2.5 inline-flex">
                                    <div className="self-stretch justify-start items-start gap-3 inline-flex">
                                      <input
                                        type="radio"
                                        name="designation"
                                        value="Staff"
                                        checked={
                                          formData.designation === "Staff"
                                        }
                                        onChange={(e) => handleRoleChange(e, 4)}
                                        className="form-radio mt-[0.36rem]"
                                      />
                                      <div className="flex-col justify-start items-start gap-2 inline-flex">
                                        <div className="self-stretch text-[#242426] text-base font-medium font-['Inter']">
                                          Staff
                                        </div>
                                        <div className="text-[#7c7c80] text-sm font-normal font-['Inter']">
                                          Manage bookings, update availability,
                                          and view basic outlet data without
                                          editing critical details.
                                        </div>
                                      </div>
                                    </div>
                                  </label>
                                </div>
                              </div>
                            </div>
                          )}

                          <div className="sm:col-span-4 w-[26rem]">
                            <label
                              htmlFor="outlet"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              Outlet
                            </label>
                            <div className="mt-2 cursor-pointer">
                              <Select
                                showSearch
                                mode="multiple"
                                style={{
                                  width: 420,
                                  minHeight: 45,
                                  padding: "0.3rem 0rem",
                                  outline: "none",
                                  border: "1px solid #C5C5C5",
                                  borderRadius: "0.25rem",
                                }}
                                placeholder="Select the outlet"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  (option?.label ?? "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                options={outletOptions}
                                value={formData.outlets}
                                onChange={(value) =>
                                  setFormData({
                                    ...formData,
                                    outlets: value,
                                  })
                                }
                              />
                            </div>
                          </div>

                          {(member?.[0]?.merchant_user_is_spoc === 1 ||
                            formData.designation === "Staff") && (
                            <div className="sm:col-span-4 w-[26rem]">
                              <div className="mt-2 flex items-center">
                                <input
                                  id="is-spoc"
                                  type="checkbox"
                                  checked={isSpoc === 1}
                                  onChange={() =>
                                    setIsSpoc(isSpoc === 1 ? 0 : 1)
                                  }
                                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                />
                                <label
                                  htmlFor="is-spoc"
                                  className="ml-3 block text-sm font-medium text-gray-700"
                                >
                                  Assign as SPOC for coordination
                                </label>
                              </div>
                            </div>
                          )}
                        </div>
                        {isSlideOverInviteOpen && (
                          <div className="inviteParent">
                            <button
                              className="inviteBttn2"
                              onClick={handleSendInvite}
                            >
                              Send invites
                            </button>
                          </div>
                        )}
                        {!isSlideOverInviteOpen && (
                          <div className="inviteParent">
                            <button
                              className="inviteBttn"
                              onClick={handleEditPermission}
                            >
                              Save
                            </button>
                          </div>
                        )}
                      </div>
                    </form>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {successMessage && (
        <div className="absolute top-[-1%] z-50 rounded-lg bg-green-50 border-none my-5 p-2 w-[40rem] mx-auto">
          <div className="ml-3">
            <h3 className="text-lg font-medium text-black">{successMessage}</h3>
          </div>
        </div>
      )}
      {errorMessage && (
        <div className="absolute top-[-1%] z-50 rounded-lg bg-red-50 border-none my-5 p-2 w-[40rem] mx-auto">
          <div className="ml-3">
            <h3 className="text-lg font-medium text-black">{errorMessage}</h3>
          </div>
        </div>
      )}
    </>
  );
}
