import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import "../Styles/slide.css";
import "react-phone-input-2/lib/style.css";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import axios from "axios";
import { DatePicker } from "rsuite";
import { useEstateCode } from "./EstateCodeContext";
import { Select } from "antd";

const getApiUrl = (path) => {
  const hostname = window.location.hostname;
  if (hostname.includes("testgrouple.com") || hostname.includes("localhost")) {
    return `https://server.testgrouple.com${path}`;
  }
  if (hostname.includes("grouple.tech")) {
    return `https://production.grouple.tech${path}`;
  }
  throw new Error("Unknown hostname");
};

export default function SlideOver({
  booking,
  onClose,
  setOpen,
  open,
  currentSection,
  fetchBookings,
  fetchBookingsCount,
  selectedSPOCFromBooking,
}) {
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const [complimentaryNotes, setComplimentaryNotes] = useState(
    booking.length > 0 ? booking[0].bookingComplimentaryNotes || "" : ""
  );
  const [notesAdded, setNotesAdded] = useState(false);

  useEffect(() => {
    if (complimentaryNotes.trim() !== "") {
      setNotesAdded(true);
    } else {
      setNotesAdded(false);
    }
  }, [complimentaryNotes]);

  const handleNotesChange = (e) => {
    setComplimentaryNotes(e.target.value);
    setIsDirty(true);
  };

  const invoiceDetails = booking[0]?.invoiceDetails
    ? JSON.parse(booking[0]?.invoiceDetails)
    : {};

  const [formData, setFormData] = useState({
    bookerFirstName: booking[0]?.bookerFirstName || "",
    bookerLastName: booking[0]?.bookerLastName || "",
    bookerEmail: booking[0]?.bookerEmail || "",
    bookerPhone: booking[0]?.bookerPhone || "",
    bookingDate: formatDate(new Date(booking[0]?.bookingDate)) || "",
    bookingSlotStartTime: booking[0]?.bookingSlotStartTime || "",
    bookingSlotEndTime: booking[0]?.bookingSlotEndTime || "",
    bookingTotalGuest: booking[0]?.bookingTotalGuest || "",
    bookingTotalGuestIndividual:
      booking[0]?.bookingPackageCategory === "À la Carte"
        ? booking[0]?.bookingTotalGuest || ""
        : "",
    bookingNotes: booking[0]?.bookingNotes || "",
    bookingPackageCategory: booking[0]?.bookingPackageCategory || "",
    bookingPackage: booking[0]?.bookingPackage || "",
    bookingGrouplePackageCategory:
      booking[0]?.bookingGrouplePackageCategory || "",

    // New fields populated from invoiceDetails
    bookerCompanyName: invoiceDetails.company_name || "",
    bookerCity: invoiceDetails.city || "",
    bookerPostalCode: invoiceDetails.postal_code || "",
    bookerCountry: invoiceDetails.country || "",
    bookerVATNumber: invoiceDetails.vat_no || "",
    bookerAddress: invoiceDetails.address || "",
    bookerState: invoiceDetails.state || "",
  });

  const [isDirty, setIsDirty] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    setOpen(true);
  }, [onClose, setOpen]);

  const renderStatus = () => {
    switch (booking[0].record_status) {
      case 0:
        return (
          <div
            className="bg-pendingBG w-28 rounded-3xl py-2 pl-4 pr-3 flex gap-2"
            style={{ alignItems: "center" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="5"
              height="6"
              viewBox="0 0 5 6"
              fill="none"
            >
              <circle cx="2.5" cy="3" r="2.5" fill="#FF9800" />
            </svg>
            <span className="text-pendingText">Pending</span>
          </div>
        );
      case 1:
        return (
          <div
            className="bg-approvedBG w-28 rounded-3xl py-1 pl-4 pr-3 flex gap-2"
            style={{ alignItems: "center" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="5"
              height="6"
              viewBox="0 0 5 6"
              fill="none"
            >
              <circle cx="2.5" cy="3" r="2.5" fill="#009600" />
            </svg>
            <span className="text-approvedText">Upcoming</span>
          </div>
        );
      case 3:
        return (
          <div
            className="bg-checkOutBG w-32 rounded-3xl py-2 pl-4 pr-3 flex gap-2"
            style={{ alignItems: "center" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="5"
              height="6"
              viewBox="0 0 5 6"
              fill="none"
            >
              <circle cx="2.5" cy="3" r="2.5" fill="#077E8C" />
            </svg>
            <span className="text-checkOutText">Completed</span>
          </div>
        );
      case 4:
        return (
          <div
            className="bg-checkInBG w-28 rounded-3xl py-1 pl-4 pr-3 flex gap-2"
            style={{ alignItems: "center" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="5"
              height="6"
              viewBox="0 0 5 6"
              fill="none"
            >
              <circle cx="2.5" cy="3" r="2.5" fill="#7389DE" />
            </svg>
            <span className="text-checkInText">Ongoing</span>
          </div>
        );
      case 2:
        return (
          <div
            className="bg-cancelledBG w-28 rounded-3xl py-2 pl-4 pr-3 flex gap-2"
            style={{ alignItems: "center" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="5"
              height="6"
              viewBox="0 0 5 6"
              fill="none"
            >
              <circle cx="2.5" cy="3" r="2.5" fill="#F44336" />
            </svg>
            <span className="text-cancelledText">Cancelled</span>
          </div>
        );
      default:
        return null;
    }
  };
  let bookingTotalGuest;
  if (booking[0]?.bookingPackageCategory === "Group Experiences") {
    bookingTotalGuest = formData.bookingTotalGuest;
  } else if (booking[0]?.bookingPackageCategory === "À la Carte") {
    bookingTotalGuest = formData.bookingTotalGuestIndividual;
  }

  const [cancelClicked, setCancelClicked] = useState(false);

  const handleCancelClick = async () => {
    if (!cancelClicked) {
      setCancelClicked(true);
    } else {
      const reservationId = booking[0].reservation_code;

      try {
        const url = getApiUrl("/api/v1/merchant/bookings/changeBookingStatus");
        const response = await axios.post(
          url,
          {
            reservation_id: reservationId,
            record_status: 2,
          },
          {
            withCredentials: true,
          }
        );

        if (response.status === 200) {
          setOpen(false);
          onClose();
          fetchBookings();
          fetchBookingsCount();
        } else {
          console.error("Cancel request failed");
        }
      } catch (error) {
        console.error("Error occurred while making the cancel request:", error);
      }
      setCancelClicked(false);
    }
  };

  const { estateCode } = useEstateCode();
  const [spoc, setSpoc] = useState([]);
  const [selectedSpoc, setSelectedSpoc] = useState(
    selectedSPOCFromBooking.spocCode || "Assign a SPOC"
  );

  const fetchSpoc = async () => {
    try {
      const url = getApiUrl(`/api/v1/merchant/bookings/getSPOC/${estateCode}`);
      const response = await axios.get(url, { withCredentials: true });
      setSpoc(response.data.data);
    } catch (error) {
      console.error("Error fetching member details:", error);
    }
  };

  useEffect(() => {
    fetchSpoc();
  }, [estateCode]);

  const spocOptions = spoc.map((spocItem) => ({
    value: spocItem.merchant_user_code,
    label: spocItem.merchant_user_name,
  }));

  const [spocError, setSpocError] = useState("");
  const [isSpocSelected, setIsSpocSelected] = useState(false);
  const handleSPOCNAMEChange = (value) => {
    setSelectedSpoc(value);
    setIsSpocSelected(value !== null);
    setIsDirty(true);
    setSpocError("");
  };

  const handleButtonClick = async (event) => {
    event.preventDefault();
    const buttonText = event.target.innerText;
    const reservationId = booking[0].reservation_code;

    if (!isSpocSelected && buttonText === "Approve") {
      setSpocError("Please assign a SPOC");
      return;
    }

    switch (buttonText) {
      case "Cancel":
        handleCancelClick();
        break;
      case "Approve":
        try {
          const url = getApiUrl(
            `/api/v1/merchant/bookings/changeBookingStatus/${selectedSpoc}`
          );
          const response = await axios.post(
            url,
            {
              reservation_id: reservationId,
              record_status: 5,
            },
            {
              withCredentials: true,
            }
          );
          if (response.status === 200) {
            console.log("Approved request successful");
            setOpen(false);
            onClose();
            fetchBookings();
            fetchBookingsCount();
          } else {
            console.error("Approved request failed");
          }
          console.log(response);
        } catch (error) {
          console.error(
            "Error occurred while making the approved request:",
            error
          );
        }
        break;
      case "Save Reservation":
        if (statusChanged) {
          try {
            const url = getApiUrl(
              "/api/v1/merchant/bookings/changeBookingStatus"
            );
            const response = await axios.post(
              url,
              {
                reservation_id: reservationId,
                record_status: selectedStatusTwo,
              },
              {
                withCredentials: true,
              }
            );

            if (response.status === 200) {
              console.log("Approved request successful");
              setOpen(false);
              onClose();
              fetchBookings();
              fetchBookingsCount();
              setStatusChanged(false);
            } else {
              console.error("Approved request failed");
            }
            console.log(response);
          } catch (error) {
            console.error(
              "Error occurred while making the approved request:",
              error
            );
          }
        } else if (notesAdded) {
          try {
            const url = getApiUrl(
              "/api/v1/merchant/bookings/editComplimentaryNotes"
            );
            const response = await axios.post(
              url,
              {
                reservation_id: reservationId,
                bookingComplimentaryNotes: complimentaryNotes,
              },
              {
                withCredentials: true,
              }
            );

            if (response.status === 200) {
              setOpen(false);
              onClose();
              fetchBookings();
              fetchBookingsCount();
              setNotesAdded(false);
              setStatusChanged(false);
            } else {
              console.error("Failed to add Notes");
            }
            console.log(response);
          } catch (error) {
            console.error("Error occurred while adding notes:", error);
          }
        } else {
          const convertTo24Hour = (time12h) => {
            const [time, modifier] = time12h.split(" ");
            let [hours, minutes] = time.split(":");
            hours = parseInt(hours);
            minutes = parseInt(minutes);
            if (hours === 12 && modifier === "AM") hours = 0;
            if (modifier === "PM" && hours < 12) hours += 12;

            hours = hours.toString().padStart(2, "0");
            minutes = minutes.toString().padStart(2, "0");
            return `${hours}:${minutes}`;
          };

          let startTime, endTime;
          if (booking[0]?.bookingPackageCategory === "À la Carte") {
            startTime = convertTo24Hour(
              selectedIndividualTimeSlot || formData.bookingSlotStartTime
            );
            endTime = startTime;
          } else {
            startTime = convertTo24Hour(formData.bookingSlotStartTime);
            endTime = convertTo24Hour(formData.bookingSlotEndTime);
          }

          const sendData = {
            bookerFirstName: formData.bookerFirstName,
            bookerLastName: formData.bookerLastName,
            bookerEmail: formData.bookerEmail,
            bookerPhone: formData.bookerPhone,
            bookingDate: formData.bookingDate,
            bookingSlotStartTime: startTime,
            bookingSlotEndTime: endTime,
            bookingTotalGuest: formData.bookingTotalGuest,
            bookingPerPersonCost: parseFloat(
              bookingPerPersonCost.replace(/,/g, "")
            ),
            bookingTotalCost: parseInt(bookingTotalCost.replace(/,/g, "")),
            bookingNotes: formData.bookingNotes,
            bookerInternationalCode: booking[0].bookerInternationalCode,
            bookingDiscountedAmount: parseInt(
              bookingDiscountedAmount.replace(/,/g, "")
            ),
            reservation_id: booking[0].reservation_code,
            spocID: selectedSpoc || "",
          };

          console.log("data", sendData);
          try {
            const url = getApiUrl("/api/v1/merchant/bookings/editBooking");
            const response = await axios.post(url, sendData, {
              withCredentials: true,
            });
            console.log("Data updated successfully:", response.data);
            setOpen(false);
            onClose();
            fetchBookings();
            fetchBookingsCount();
          } catch (error) {
            console.error("Error occurred while updating data:", error);
          }
        }

        break;

      default:
        console.log("Unknown button clicked");
    }
  };

  const [isTimeSlotOpen, setIsTimeSlotOpen] = useState(false);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [currentDay, setCurrentDay] = useState(null);

  const getCurrentDay = (selectedDate) => {
    if (!selectedDate) return null;

    // Convert to Date object if it's not already
    const dateObj = new Date(selectedDate);

    // Ensure that the conversion to Date object is valid
    if (isNaN(dateObj.getTime())) {
      console.error("Invalid date:", selectedDate);
      return null;
    }

    // Now call toLocaleDateString on the valid Date object
    return dateObj
      .toLocaleDateString("en-US", { weekday: "short" })
      .toLowerCase();
  };

  useEffect(() => {
    if (formData.bookingDate) {
      setCurrentDay(getCurrentDay(formData.bookingDate));
    } else {
      setCurrentDay(null);
    }
  }, [formData.bookingDate]);

  const filteredTimeSlots =
    currentDay && booking[0]?.packageTimeslots
      ? booking[0].packageTimeslots
          .filter((slot) => slot.package_day === currentDay)
          .map((slot) => ({
            value: `${slot.package_min_time_slot} - ${slot.package_max_time_slot}`,
            label: `${new Date(
              `1970-01-01T${slot.package_min_time_slot}`
            ).toLocaleTimeString("en-US", {
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            })} - ${new Date(
              `1970-01-01T${slot.package_max_time_slot}`
            ).toLocaleTimeString("en-US", {
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            })}`,
          }))
      : [];

  const handleSelectedTimeSlot = (timeSlotValue) => {
    setSelectedTimeSlot(timeSlotValue);

    const [startTime, endTime] = timeSlotValue.split(" - ");

    setFormData((prevFormData) => ({
      ...prevFormData,
      bookingSlotStartTime: startTime,
      bookingSlotEndTime: endTime,
    }));

    setIsDirty(true);
    setIsTimeSlotOpen(false);
  };

  const [isIndividualTimeSlotOpen, setIsIndividualTimeSlotOpen] =
    useState(false);
  const [selectedIndividualTimeSlot, setSelectedIndividualTimeSlot] =
    useState(null);

  const handleSelectedIndividualTimeSlot = (timeSlotIndividualValue) => {
    setSelectedIndividualTimeSlot(timeSlotIndividualValue);

    setFormData((prevFormData) => ({
      ...prevFormData,
      bookingSlotStartTime: timeSlotIndividualValue,
      bookingSlotEndTime: timeSlotIndividualValue,
    }));
    setIsDirty(true);
    setIsIndividualTimeSlotOpen(false);
  };

  const formatNumber = (number) => {
    if (parseFloat(number) !== 0) {
      const formattedNumber = parseFloat(number).toFixed(0);
      return formattedNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return number.toString();
    }
  };

  const [
    bookingPerPersonCostWithoutDiscount,
    setBookingPerPersonCostWithoutDiscount,
  ] = useState(
    formatNumber(booking[0]?.bookingPerPersonCostWithoutDiscount || "")
  );
  const [bookingPerPersonCost, setBookingPerPersonCost] = useState(
    formatNumber(booking[0]?.bookingPerPersonCost || 0)
  );
  const [bookingDiscountedAmount, setBookingDiscountedAmount] = useState(
    formatNumber(booking[0]?.bookingDiscountedAmount || 0)
  );
  const [bookingTotalCost, setBookingTotalCost] = useState(
    formatNumber(booking[0]?.bookingTotalCost || 0)
  );
  useEffect(() => {
    setBookingPerPersonCostWithoutDiscount(
      formatNumber(booking[0]?.bookingPerPersonCostWithoutDiscount || 0)
    );
    setBookingPerPersonCost(
      formatNumber(booking[0]?.bookingPerPersonCost || 0)
    );
    setBookingDiscountedAmount(
      formatNumber(booking[0]?.bookingDiscountedAmount || 0)
    );
    setBookingTotalCost(formatNumber(booking[0]?.bookingTotalCost || 0));
  }, [booking]);

  const [recordStatus, setRecordStatus] = useState(booking[0].record_status);
  const [originalGroupSize, setOriginalGroupSize] = useState(
    formData.bookingTotalGuest
  );
  const [originalIndividualGroupSize, setOriginalIndividualGroupSize] =
    useState(formData.bookingTotalGuestIndividual);

  const [initialGroupSize, setInitialGroupSize] = useState(
    formData.bookingTotalGuest
  );

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "bookingTotalGuest") {
      const newValue = parseInt(value);
      const originalValue = parseInt(initialGroupSize);
      if (newValue > originalValue) {
        setOriginalGroupSize(newValue.toString());
      }
      if (isNaN(newValue)) {
        setErrorMessage("Please enter a valid number");
      } else if (
        newValue < booking[0].package[0].min_pax ||
        newValue > booking[0].package[0].max_pax
      ) {
        setErrorMessage(
          `Group size must be between ${booking[0].package[0].min_pax} and ${booking[0].package[0].max_pax}`
        );
      } else if (newValue < originalValue) {
        setErrorMessage("You can only increase the group size");
      } else {
        setErrorMessage("");
        setOriginalGroupSize(newValue.toString());
      }

      const totalGuest = newValue;

      // let bookingPerPersonCost;
      // if (totalGuest >= 250) {
      //   if (booking[0].bookingGrouplePackageCategory === "Regular") {
      //     bookingPerPersonCost = 199;
      //   } else if (booking[0].bookingGrouplePackageCategory === "Premium") {
      //     bookingPerPersonCost = 299;
      //   } else if (booking[0].bookingGrouplePackageCategory === "Exclusive") {
      //     bookingPerPersonCost = 399;
      //   } else if (
      //     booking[0].bookingGrouplePackageCategory === "Ultra Exclusive"
      //   ) {
      //     bookingPerPersonCost = 499;
      //   }
      // } else if (totalGuest >= 150) {
      //   if (booking[0].bookingGrouplePackageCategory === "Regular") {
      //     bookingPerPersonCost = 219;
      //   } else if (booking[0].bookingGrouplePackageCategory === "Premium") {
      //     bookingPerPersonCost = 319;
      //   } else if (booking[0].bookingGrouplePackageCategory === "Exclusive") {
      //     bookingPerPersonCost = 419;
      //   } else if (
      //     booking[0].bookingGrouplePackageCategory === "Ultra Exclusive"
      //   ) {
      //     bookingPerPersonCost = 519;
      //   }
      // } else if (totalGuest >= 50) {
      //   if (booking[0].bookingGrouplePackageCategory === "Regular") {
      //     bookingPerPersonCost = 234;
      //   } else if (booking[0].bookingGrouplePackageCategory === "Premium") {
      //     bookingPerPersonCost = 334;
      //   } else if (booking[0].bookingGrouplePackageCategory === "Exclusive") {
      //     bookingPerPersonCost = 434;
      //   } else if (
      //     booking[0].bookingGrouplePackageCategory === "Ultra Exclusive"
      //   ) {
      //     bookingPerPersonCost = 534;
      //   }
      // } else {
      //   if (booking[0].bookingGrouplePackageCategory === "Regular") {
      //     bookingPerPersonCost = 249;
      //   } else if (booking[0].bookingGrouplePackageCategory === "Premium") {
      //     bookingPerPersonCost = 349;
      //   } else if (booking[0].bookingGrouplePackageCategory === "Exclusive") {
      //     bookingPerPersonCost = 449;
      //   } else if (
      //     booking[0].bookingGrouplePackageCategory === "Ultra Exclusive"
      //   ) {
      //     bookingPerPersonCost = 549;
      //   }
      // }

      setBookingPerPersonCost(bookingPerPersonCost);
      const newDiscountedAmount = formatNumber(
        bookingPerPersonCostWithoutDiscount * totalGuest -
          bookingPerPersonCost * totalGuest
      );
      const newTotalCost = formatNumber(bookingPerPersonCost * totalGuest);

      setBookingDiscountedAmount(newDiscountedAmount);
      setBookingTotalCost(newTotalCost);

      setFormData((prevState) => ({
        ...prevState,
        [name]: newValue.toString(),
      }));
    } else if (name === "bookingTotalGuestIndividual") {
      let newValue = parseInt(value);
      const originalValue = parseInt(initialGroupSize);

      if (newValue > originalValue) {
        setOriginalGroupSize(newValue.toString());
      }

      if (booking[0].bookingPackageCategory === "À la Carte") {
        if (isNaN(newValue)) {
          setErrorMessage("Please enter a valid number");
        } else if (newValue < 1 || newValue > 10) {
          setErrorMessage("Group size must be between 1 and 10");
        } else if (newValue < originalValue) {
          setErrorMessage("You can only increase the group size");
        } else {
          setErrorMessage("");
          setOriginalGroupSize(newValue.toString());
        }
      }

      setFormData((prevState) => ({
        ...prevState,
        [name]: newValue.toString(),
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }

    setIsDirty(true);
  };

  const handleDateChange = (date) => {
    const formattedDate = formatDate(date);
    setFormData((prevState) => ({
      ...prevState,
      bookingDate: formattedDate,
    }));
    setIsDirty(true);
  };

  function getCurrentDate() {
    const today = new Date();
    let month = String(today.getMonth() + 1);
    let day = String(today.getDate());
    const year = today.getFullYear();

    if (month.length < 2) {
      month = "0" + month;
    }
    if (day.length < 2) {
      day = "0" + day;
    }

    return `${year}-${month}-${day}`;
  }
  // Status change dropdown //
  const [isStatusOpenTwo, setIsStatusOpenTwo] = useState(false);
  const [selectedStatusTwo, setSelectedStatusTwo] = useState(null);
  const [displayedStatusTwo, setDisplayedStatusTwo] = useState("Status");
  const [statusChanged, setStatusChanged] = useState(false);

  const getStatusOptions = (recordStatus, bookingDate) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const bookingDateObj = new Date(bookingDate);
    bookingDateObj.setHours(0, 0, 0, 0);

    if (recordStatus === 5 || recordStatus === 1) {
      if (bookingDateObj >= today) {
        return [
          { value: 2, label: "Cancelled" },
          { value: 4, label: "Ongoing" },
        ];
      } else {
        return [
          { value: 2, label: "Cancelled" },
          { value: 3, label: "Completed" },
        ];
      }
    } else if (recordStatus === 4) {
      return [{ value: 3, label: "Completed" }];
    } else {
      return [{ value: 0, label: "Custom Status" }];
    }
  };

  // Example usage in your component
  const statusOptionsTwo = getStatusOptions(recordStatus, formData.bookingDate);

  useEffect(() => {
    if (recordStatus === 5 || recordStatus === 1) {
      setSelectedStatusTwo(5);
      setDisplayedStatusTwo("Upcoming");
    } else if (recordStatus === 4) {
      setSelectedStatusTwo(4);
      setDisplayedStatusTwo("Ongoing");
    } else {
      setSelectedStatusTwo(null);
      setDisplayedStatusTwo("Status");
    }
  }, [recordStatus]);

  const handleSelectedStatusTwo = (status) => {
    if (status === null) {
      setSelectedStatusTwo(null);
      setDisplayedStatusTwo("Status");
      setIsStatusOpenTwo(false);
    } else {
      setSelectedStatusTwo(status);
      const selectedOption = statusOptionsTwo.find(
        (option) => option.value === status
      );
      if (selectedOption) {
        setDisplayedStatusTwo(selectedOption.label);
        setIsDirty(true);
        setStatusChanged(true);
      } else {
        setDisplayedStatusTwo("Status");
      }
      setIsStatusOpenTwo(false);
    }
  };

  const isPastDate = (selectedDate) => {
    const currentDate = new Date();
    const selectedDateObj = new Date(selectedDate);
    currentDate.setHours(0, 0, 0, 0);
    selectedDateObj.setHours(0, 0, 0, 0);
    return selectedDateObj < currentDate;
  };

  const blockedDays = [];

  const renderCategoryItems = (category, categoryItems) => {
    return (
      <div key={category}>
        <div className="menuCategoryHeading">{category}</div>
        {categoryItems.map((item) => (
          <div key={item.fnb_item_code} className="menuItemNameWrapper">
            <div>
              {item.fnb_type === "NON-VEG" ? (
                <svg
                  width="13"
                  height="13"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.5"
                    y="0.5"
                    width="15"
                    height="15"
                    rx="1.5"
                    stroke="#963415"
                  />
                  <path
                    d="M4.38833 12C4.20992 12 4.09388 11.8122 4.17367 11.6527L7.78534 4.42932C7.87378 4.25243 8.12622 4.25243 8.21466 4.42933L11.8263 11.6527C11.9061 11.8122 11.7901 12 11.6117 12H4.38833Z"
                    fill="#963415"
                  />
                </svg>
              ) : (
                <svg
                  width="13"
                  height="13"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.5"
                    y="0.5"
                    width="15"
                    height="15"
                    rx="1.5"
                    stroke="#009600"
                  />
                  <circle cx="8" cy="8" r="4" fill="#009600" />
                </svg>
              )}
            </div>
            <div>{item.fnb_item_name}</div>
          </div>
        ))}
      </div>
    );
  };

  const renderMenu = (menuData, categoryType) => {
    if (!menuData || Object.keys(menuData).length === 0) {
      return null;
    }

    const categories = Object.keys(menuData).map((categoryCode) => {
      const category = booking[0]?.package_categories?.package_categories?.find(
        (cat) => cat.fnb_category_code === categoryCode
      );
      return {
        categoryName: category?.fnb_category || "Unknown Category",
        items: menuData[categoryCode],
      };
    });

    const categorySummary = categories
      .map((cat) => `${cat.items.length} ${cat.categoryName}`)
      .join(", ");

    return (
      <div className="w-full mt-3 rounded-2xl bg-white">
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button
                className={`foodMenuDropdownButton flex px-3 py-3 w-full justify-between border border-gray-300 rounded-xl ${
                  open ? "" : ""
                }`}
              >
                <span className=" text-base font-medium text-gray-950">
                  {categorySummary}
                </span>
                <ChevronDownIcon
                  className={`${
                    open ? "rotate-180 transform" : ""
                  } h-5 w-5 text-black-500`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="dropdownMenuWrapper">
                {categories.map((cat) =>
                  renderCategoryItems(cat.categoryName, cat.items)
                )}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

        {/* <Disclosure>
                                    {({ open }) => (
                                      <>
                                        <Disclosure.Button
                                          className={`foodMenuDropdownButton flex px-3 py-3 w-full justify-between border border-gray-300 rounded-xl ${
                                            open ? "" : ""
                                          }`}
                                        >
                                          <span className=" text-base font-medium text-gray-950">
                                            {choiceA} Appetisers, {choiceM} Main
                                            Course, {choiceD} Desserts, Sides
                                          </span>
                                          <ChevronDownIcon
                                            className={`${
                                              open ? "rotate-180 transform" : ""
                                            } h-5 w-5 text-black-500`}
                                          />
                                        </Disclosure.Button>
                                        <Disclosure.Panel className="dropdownMenuWrapper">
                                          {Object.keys(
                                            booking[0]?.foodItemSelected.food ||
                                              {}
                                          ).map((category) => (
                                            <div key={category}>
                                              <div className="menuCategoryHeading">
                                                {category
                                                  .replace(/_/g, " ")
                                                  .charAt(0)
                                                  .toUpperCase() +
                                                  category
                                                    .replace(/_/g, " ")
                                                    .slice(1)}
                                              </div>

                                              {Array.isArray(
                                                booking[0]?.foodItemSelected
                                                  .food[category]
                                              ) ? (
                                                booking[0]?.foodItemSelected.food[
                                                  category
                                                ].map((item, index) => (
                                                  <div
                                                    key={item.item_code}
                                                    className={`menuItemNameWrapper ${
                                                      index !==
                                                      booking[0]
                                                        ?.foodItemSelected.food[
                                                        category
                                                      ].length -
                                                        1
                                                        ? ""
                                                        : ""
                                                    }`}
                                                  >
                                                    <div>
                                                      {item.item_category ===
                                                      "Non Vegetarian" ? (
                                                        <svg
                                                          width="13"
                                                          height="13"
                                                          viewBox="0 0 16 16"
                                                          fill="none"
                                                          xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                          <rect
                                                            x="0.5"
                                                            y="0.5"
                                                            width="15"
                                                            height="15"
                                                            rx="1.5"
                                                            stroke="#963415"
                                                          />
                                                          <path
                                                            d="M4.38833 12C4.20992 12 4.09388 11.8122 4.17367 11.6527L7.78534 4.42932C7.87378 4.25243 8.12622 4.25243 8.21466 4.42933L11.8263 11.6527C11.9061 11.8122 11.7901 12 11.6117 12H4.38833Z"
                                                            fill="#963415"
                                                          />
                                                        </svg>
                                                      ) : (
                                                        <svg
                                                          width="13"
                                                          height="13"
                                                          viewBox="0 0 16 16"
                                                          fill="none"
                                                          xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                          <rect
                                                            x="0.5"
                                                            y="0.5"
                                                            width="15"
                                                            height="15"
                                                            rx="1.5"
                                                            stroke="#009600"
                                                          />
                                                          <circle
                                                            cx="8"
                                                            cy="8"
                                                            r="4"
                                                            fill="#009600"
                                                          />
                                                        </svg>
                                                      )}
                                                    </div>
                                                    <div className="font-normal text-sm text-gray-900">
                                                      {item.item_name}
                                                    </div>
                                                  </div>
                                                ))
                                              ) : (
                                                <div>
                                                  Category items not found
                                                </div>
                                              )}
                                            </div>
                                          ))}
                                        </Disclosure.Panel>
                                      </>
                                    )}
                                  </Disclosure> */}
      </div>
    );
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={setOpen}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
                onClick={(e) => e.stopPropagation()}
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-xl">
                  <form className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                    <div className="h-0 flex-1 overflow-y-auto">
                      <div className="bg-white z-10 sticky top-0 px-4 py-4 sm:px-6 border-b">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="details">
                            Reservation details
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="relative rounded-md text-black"
                              onClick={() => {
                                setOpen(false);
                                onClose();
                              }}
                            >
                              <span className="absolute -inset-2.5" />
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-1 flex-col justify-between">
                        <p className=" px-6 pt-5 reservationId">
                          Booking ID {booking[0].reservation_code}
                        </p>

                        <p className=" px-6  name">
                          {booking[0].bookerFirstName}{" "}
                          {booking[0].bookerLastName}
                        </p>

                        {/* status & assigned div  */}
                        <div className="biggerBox">
                          {recordStatus === 1 ||
                          recordStatus === 4 ||
                          recordStatus === 5 ? (
                            <div className="statusBox border-red-900">
                              <p>Status</p>
                              <div
                                className="w-full relative"
                                style={{ cursor: "pointer" }}
                              >
                                <div
                                  className={`w-full ${
                                    selectedStatusTwo ? "active" : ""
                                  }`}
                                  onClick={() =>
                                    setIsStatusOpenTwo(!isStatusOpenTwo)
                                  }
                                >
                                  <div className="flex justify-between gap-y-3 mt-2">
                                    <div>
                                      <div className="Time-Value ">
                                        {displayedStatusTwo === "Upcoming" && (
                                          <div
                                            className="bg-approvedBG w-28 rounded-3xl py-3 pl-4 pr-3 flex gap-2"
                                            style={{ alignItems: "center" }}
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="5"
                                              height="6"
                                              viewBox="0 0 5 6"
                                              fill="none"
                                            >
                                              <circle
                                                cx="2.5"
                                                cy="3"
                                                r="2.5"
                                                fill="#009600"
                                              />
                                            </svg>
                                            <span className="text-approvedText">
                                              Upcoming
                                            </span>
                                          </div>
                                        )}
                                        {displayedStatusTwo === "Ongoing" && (
                                          <div
                                            className="bg-checkInBG w-30 rounded-3xl py-3 pl-4 pr-3 flex gap-2"
                                            style={{ alignItems: "center" }}
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="5"
                                              height="6"
                                              viewBox="0 0 5 6"
                                              fill="none"
                                            >
                                              <circle
                                                cx="2.5"
                                                cy="3"
                                                r="2.5"
                                                fill="#7389DE"
                                              />
                                            </svg>
                                            <span className="text-checkInText">
                                              Ongoing
                                            </span>
                                          </div>
                                        )}
                                        {displayedStatusTwo === "Completed" && (
                                          <div
                                            className="bg-checkOutBG w-32 rounded-3xl py-3 pl-4 pr-3 flex gap-2"
                                            style={{ alignItems: "center" }}
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="5"
                                              height="6"
                                              viewBox="0 0 5 6"
                                              fill="none"
                                            >
                                              <circle
                                                cx="2.5"
                                                cy="3"
                                                r="2.5"
                                                fill="#077E8C"
                                              />
                                            </svg>
                                            <span className="text-checkOutText">
                                              Completed
                                            </span>
                                          </div>
                                        )}
                                        {displayedStatusTwo === "Cancelled" && (
                                          <div
                                            className="bg-cancelledBG w-28 rounded-3xl py-3 pl-4 pr-3 flex gap-2"
                                            style={{ alignItems: "center" }}
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="5"
                                              height="6"
                                              viewBox="0 0 5 6"
                                              fill="none"
                                            >
                                              <circle
                                                cx="2.5"
                                                cy="3"
                                                r="2.5"
                                                fill="#F44336"
                                              />
                                            </svg>
                                            <span className="text-cancelledText">
                                              Cancelled
                                            </span>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                    <div>
                                      <ChevronDownIcon
                                        className={`chevron-icon mt-3 ${
                                          isStatusOpenTwo ? "up" : "down"
                                        }`}
                                        style={{
                                          transform: isStatusOpenTwo
                                            ? "rotate(180deg)"
                                            : "none",
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                {isStatusOpenTwo && (
                                  <div className="dropdown-packageOptions-slideOver">
                                    {statusOptionsTwo.map((option) => (
                                      <div
                                        key={option.value}
                                        style={{ borderRadius: "0.25rem" }}
                                        className={`dropdown-option-slideover flex ${
                                          selectedStatusTwo === option.value
                                            ? "selected"
                                            : ""
                                        }`}
                                        onClick={() =>
                                          handleSelectedStatusTwo(option.value)
                                        }
                                      >
                                        {option.label === "Upcoming" && (
                                          <div
                                            className="bg-approvedBG w-28 rounded-3xl py-3 pl-4 pr-3 flex gap-2"
                                            style={{ alignItems: "center" }}
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="5"
                                              height="6"
                                              viewBox="0 0 5 6"
                                              fill="none"
                                            >
                                              <circle
                                                cx="2.5"
                                                cy="3"
                                                r="2.5"
                                                fill="#009600"
                                              />
                                            </svg>
                                            <span className="text-approvedText">
                                              Upcoming
                                            </span>
                                          </div>
                                        )}

                                        {option.label === "Ongoing" && (
                                          <div
                                            className="bg-checkInBG w-30 rounded-3xl py-3 pl-4 pr-3 flex gap-2"
                                            style={{ alignItems: "center" }}
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="5"
                                              height="6"
                                              viewBox="0 0 5 6"
                                              fill="none"
                                            >
                                              <circle
                                                cx="2.5"
                                                cy="3"
                                                r="2.5"
                                                fill="#7389DE"
                                              />
                                            </svg>
                                            <span className="text-checkInText">
                                              Ongoing
                                            </span>
                                          </div>
                                        )}
                                        {option.label === "Completed" && (
                                          <div
                                            className="bg-checkOutBG w-32 rounded-3xl py-3 pl-4 pr-3 flex gap-2"
                                            style={{ alignItems: "center" }}
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="5"
                                              height="6"
                                              viewBox="0 0 5 6"
                                              fill="none"
                                            >
                                              <circle
                                                cx="2.5"
                                                cy="3"
                                                r="2.5"
                                                fill="#077E8C"
                                              />
                                            </svg>
                                            <span className="text-checkOutText">
                                              Completed
                                            </span>
                                          </div>
                                        )}
                                        {option.label === "Cancelled" && (
                                          <div
                                            className="bg-cancelledBG w-28 rounded-3xl py-3 pl-4 pr-3 flex gap-2"
                                            style={{ alignItems: "center" }}
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="5"
                                              height="6"
                                              viewBox="0 0 5 6"
                                              fill="none"
                                            >
                                              <circle
                                                cx="2.5"
                                                cy="3"
                                                r="2.5"
                                                fill="#F44336"
                                              />
                                            </svg>
                                            <span className="text-cancelledText">
                                              Cancelled
                                            </span>
                                          </div>
                                        )}
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </div>
                            </div>
                          ) : (
                            <div className="statusBox">
                              <p>Status</p>
                              <div className="block w-full border-0  py-2.5 text-black focus:ring-groupleBlue focus:bg-white sm:text-sm sm:leading-6">
                                <div>{renderStatus()}</div>
                              </div>
                            </div>
                          )}

                          <div className="statusBox">
                            <p>Assign</p>
                            <div className=" text-black mt-3.5 ">
                              {booking[0].bookingPackage ===
                              "General Reservations" ? (
                                "- -"
                              ) : (
                                <div className="sm:col-span-4 w-[14rem]">
                                  <div className="mt-2 cursor-pointer">
                                    <Select
                                      showSearch
                                      mode="single"
                                      style={{
                                        width: 220,
                                        // height: 45,
                                        border: "1px solid #C5C5C5",
                                        borderRadius: "0.25rem",
                                      }}
                                      placeholder="Assign a SPOC"
                                      optionFilterProp="children"
                                      filterOption={(input, option) =>
                                        (option?.label ?? "").includes(input)
                                      }
                                      filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? "")
                                          .toLowerCase()
                                          .localeCompare(
                                            (optionB?.label ?? "").toLowerCase()
                                          )
                                      }
                                      options={spocOptions}
                                      value={selectedSpoc}
                                      onChange={handleSPOCNAMEChange}
                                      disabled={
                                        (recordStatus !== 1 &&
                                          recordStatus !== 0 &&
                                          recordStatus !== 5) ||
                                        isPastDate(formData.bookingDate)
                                      }
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                            {spocError && (
                              <div className="text-red-500 mt-2">
                                {spocError}
                              </div>
                            )}
                          </div>
                        </div>

                        {/* Booking Details */}
                        <div className="bookingDetails">
                          <h2>Booking Details</h2>
                          <div className="insideDetails">
                            <h3>Booking Date</h3>

                            <div className="mt-1 inner-input w-full">
                              <DatePicker
                                placeholder="Select a date"
                                oneTap
                                format="dd-MMM-yyyy"
                                size="lg"
                                value={new Date(formData.bookingDate)}
                                onChange={handleDateChange}
                                renderExtraFooter={() => null}
                                cleanable={false}
                                readOnly={
                                  (recordStatus !== 1 && recordStatus !== 5) ||
                                  isPastDate(formData.bookingDate)
                                }
                                disabledDate={(date) => {
                                  const day = date.getDay();
                                  return blockedDays.includes(day);
                                }}
                                className="custom-date-range-picker2 z-50 text-black"
                              />
                            </div>
                          </div>
                          {/* timeslot */}
                          <div className="insideDetails">
                            <h3>Time Slot</h3>

                            <div className="mt-1 inner-input ">
                              <div
                                className="sort-wrapper-main-two"
                                style={{
                                  cursor:
                                    (recordStatus !== 1 &&
                                      recordStatus !== 5) ||
                                    isPastDate(formData.bookingDate)
                                      ? "not-allowed"
                                      : "pointer",
                                }}
                              >
                                <div className="custom-dropdown-two">
                                  <div
                                    className={`block w-full rounded-lg border-0 bg-gray-100 py-2.5 text-black shadow-sm ring-1 ring-inset ring-white/10 focus:ring-1 focus:ring-groupleBlue px-3 focus:bg-white sm:text-sm sm:leading-6 ${
                                      (recordStatus !== 1 &&
                                        recordStatus !== 5) ||
                                      isPastDate(formData.bookingDate)
                                        ? "cursor-not-allowed"
                                        : ""
                                    } ${selectedTimeSlot ? "active" : ""}`}
                                    onClick={() => {
                                      if (
                                        (recordStatus !== 1 &&
                                          recordStatus !== 5) ||
                                        isPastDate(formData.bookingDate)
                                      )
                                        return;
                                      setIsTimeSlotOpen(!isTimeSlotOpen);
                                    }}
                                  >
                                    {selectedTimeSlot ? (
                                      <div className="Time-placeHolder">
                                        <div>
                                          <div className="Time-Value">
                                            <>
                                              {formData.bookingSlotStartTime} -{" "}
                                              {formData.bookingSlotEndTime}
                                            </>
                                          </div>
                                        </div>
                                        <div>
                                          <ChevronDownIcon
                                            className={`chevron-icon ${
                                              isTimeSlotOpen ? "up" : "down"
                                            }`}
                                            style={{
                                              transform: isTimeSlotOpen
                                                ? "rotate(180deg)"
                                                : "none",
                                            }}
                                          />
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="Time-placeHolder flex justify-between">
                                        <div>
                                          <>
                                            {formData.bookingSlotStartTime} -{" "}
                                            {formData.bookingSlotEndTime}
                                          </>
                                        </div>
                                        <div>
                                          <ChevronDownIcon
                                            className={`chevron-icon ${
                                              isTimeSlotOpen ? "up" : "down"
                                            }`}
                                            style={{
                                              transform: isTimeSlotOpen
                                                ? "rotate(180deg)"
                                                : "none",
                                            }}
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                  {isTimeSlotOpen && (
                                    <div className="dropdown-packageOptions-timeSlot-two">
                                      {filteredTimeSlots.map((option) => (
                                        <div
                                          key={option.label}
                                          style={{ borderRadius: "0.25rem" }}
                                          className={`dropdown-packageOption-timeSlot-two flex justify-between hover:bg-customLilac hover:text-groupleBlue ${
                                            selectedTimeSlot === option.label
                                              ? "selected"
                                              : ""
                                          }`}
                                          onClick={() =>
                                            handleSelectedTimeSlot(option.label)
                                          }
                                        >
                                          {option.label}
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* group size */}
                          <div className="insideDetails">
                            <h3>Group Size</h3>
                            <div className="mt-1 inner-input">
                              <input
                                type="number"
                                name="bookingTotalGuest"
                                id="bookingTotalGuest"
                                value={formData.bookingTotalGuest}
                                min={booking[0]?.package[0]?.min_pax}
                                max={booking[0]?.package[0]?.max_pax}
                                onChange={handleInputChange}
                                readOnly
                                // ={
                                //   recordStatus !== 1 ||
                                //   isPastDate(formData.bookingDate)
                                // }
                                className={`date-input block w-full rounded-lg border-0 bg-gray-100 py-2.5 px-3 text-black shadow-sm ring-1 ring-inset ring-white/10 ${
                                  (recordStatus !== 1 && recordStatus !== 5) ||
                                  isPastDate(formData.bookingDate)
                                    ? "pointer-events-none focus:ring-transparent"
                                    : "focus:ring-groupleBlue"
                                } focus:ring-1 cursor-pointer focus:bg-white sm:text-sm sm:leading-6`}
                              />
                            </div>
                          </div>
                          {/* {booking[0]?.bookingPackageCategory ===
                            "À la Carte" && (
                            <div className="insideDetails">
                              <h3>Group Size</h3>
                              <div className="mt-1 inner-input">
                                <input
                                  type="number"
                                  name="bookingTotalGuestIndividual"
                                  id="bookingTotalGuestIndividual"
                                  value={formData.bookingTotalGuestIndividual}
                                  min={1}
                                  max={10}
                                  onChange={handleInputChange}
                                  readOnly={
                                    recordStatus !== 1 ||
                                    isPastDate(formData.bookingDate)
                                  }
                                  className={`date-input block w-full rounded-lg border-0 bg-gray-100 py-2.5 px-3 text-black shadow-sm ring-1 ring-inset ring-white/10 ${
                                    recordStatus !== 1 ||
                                    isPastDate(formData.bookingDate)
                                      ? "pointer-events-none focus:ring-transparent"
                                      : "focus:ring-groupleBlue"
                                  } focus:ring-1 cursor-pointer focus:bg-white sm:text-sm sm:leading-6`}
                                />
                              </div>
                            </div>
                          )} */}
                          {errorMessage && (
                            <p className="text-red-500">{errorMessage}</p>
                          )}
                        </div>

                        {/* Clients Details */}
                        <div className="bookingDetails">
                          <h2>Clients Details</h2>
                          <div className="insideDetails">
                            <h3>First name</h3>

                            <div className="mt-1 inner-input">
                              <input
                                type="text"
                                name="bookerFirstName"
                                id="bookerFirstName"
                                value={formData.bookerFirstName}
                                onChange={handleInputChange}
                                readOnly={
                                  (recordStatus !== 1 && recordStatus !== 5) ||
                                  isPastDate(formData.bookingDate)
                                }
                                className={`date-input block w-full rounded-lg border-0 bg-gray-100 py-2.5 px-3 text-black shadow-sm ring-1 ring-inset ring-white/10 ${
                                  (recordStatus !== 1 && recordStatus !== 5) ||
                                  isPastDate(formData.bookingDate)
                                    ? "pointer-events-none focus:ring-transparent"
                                    : "focus:ring-groupleBlue"
                                } focus:ring-1 cursor-pointer focus:bg-white sm:text-sm sm:leading-6`}
                              />
                            </div>
                          </div>

                          <div className="insideDetails">
                            <h3>Last name</h3>

                            <div className="mt-1 inner-input">
                              <input
                                type="text"
                                name="bookerLastName"
                                id="bookerLastName"
                                value={formData.bookerLastName}
                                onChange={handleInputChange}
                                readOnly={
                                  (recordStatus !== 1 && recordStatus !== 5) ||
                                  isPastDate(formData.bookingDate)
                                }
                                className={`date-input block w-full rounded-lg border-0 bg-gray-100 py-2.5 px-3 text-black shadow-sm ring-1 ring-inset ring-white/10 ${
                                  (recordStatus !== 1 && recordStatus !== 5) ||
                                  isPastDate(formData.bookingDate)
                                    ? "pointer-events-none focus:ring-transparent"
                                    : "focus:ring-groupleBlue"
                                } focus:ring-1 cursor-pointer focus:bg-white sm:text-sm sm:leading-6`}
                              />
                            </div>
                          </div>

                          <div className="insideDetails">
                            <h3>Email</h3>

                            <div className="mt-1 inner-input">
                              <input
                                type="email"
                                name="bookerEmail"
                                id="bookerEmail"
                                value={formData.bookerEmail}
                                onChange={handleInputChange}
                                readOnly={
                                  (recordStatus !== 1 && recordStatus !== 5) ||
                                  isPastDate(formData.bookingDate)
                                }
                                className={`date-input block w-full rounded-lg border-0 bg-gray-100 py-2.5 px-3 text-black shadow-sm ring-1 ring-inset ring-white/10 ${
                                  (recordStatus !== 1 && recordStatus !== 5) ||
                                  isPastDate(formData.bookingDate)
                                    ? "pointer-events-none focus:ring-transparent"
                                    : "focus:ring-groupleBlue"
                                } focus:ring-1 cursor-pointer focus:bg-white sm:text-sm sm:leading-6`}
                              />
                            </div>
                          </div>

                          <div className="insideDetails">
                            <h3>Phone Numbers</h3>

                            <div className="mt-1 inner-input">
                              <input
                                type="number"
                                name="bookerPhone"
                                id="bookerPhone"
                                value={formData.bookerPhone}
                                onChange={handleInputChange}
                                readOnly={
                                  (recordStatus !== 1 && recordStatus !== 5) ||
                                  isPastDate(formData.bookingDate)
                                }
                                className={`date-input block w-full rounded-lg border-0 bg-gray-100 py-2.5 px-3 text-black shadow-sm ring-1 ring-inset ring-white/10 ${
                                  (recordStatus !== 1 && recordStatus !== 5) ||
                                  isPastDate(formData.bookingDate)
                                    ? "pointer-events-none focus:ring-transparent"
                                    : "focus:ring-groupleBlue"
                                } focus:ring-1 cursor-pointer focus:bg-white sm:text-sm sm:leading-6`}
                              />
                            </div>
                          </div>

                          <div className="insideDetails">
                            <h3>Reservation Notes</h3>

                            <div className="mt-1 inner-input">
                              <textarea
                                name="bookingNotes"
                                id="bookingNotes"
                                value={formData.bookingNotes}
                                onChange={handleInputChange}
                                readOnly={
                                  (recordStatus !== 1 && recordStatus !== 5) ||
                                  isPastDate(formData.bookingDate)
                                }
                                className={`date-input block w-full rounded-lg border-0 bg-gray-100 py-2.5 px-3 text-black shadow-sm ring-1 ring-inset ring-white/10 ${
                                  (recordStatus !== 1 && recordStatus !== 5) ||
                                  isPastDate(formData.bookingDate)
                                    ? "pointer-events-none focus:ring-transparent"
                                    : "focus:ring-groupleBlue"
                                } focus:ring-1 cursor-pointer focus:bg-white sm:text-sm sm:leading-6`}
                              ></textarea>
                            </div>
                          </div>
                        </div>

                        {/* Package Details */}
                        <div className="bookingDetails">
                          <h2>Package Details</h2>
                          <div className="insideDetails">
                            <h3>Category</h3>

                            <div className="mt-1 inner-input block w-full rounded-lg border-0 bg-white-100  py-2.5 text-black  px-3 sm:text-sm sm:leading-6">
                              {formData.bookingPackageCategory}
                            </div>
                          </div>

                          <div className="insideDetails">
                            <h3>Package</h3>

                            <div className="mt-1 inner-input block w-full rounded-lg border-0 bg-white-100  py-2.5 text-black  px-3 sm:text-sm sm:leading-6">
                              {formData.bookingGrouplePackageCategory}
                            </div>
                          </div>

                          <div className="insideDetails">
                            <h3>Package Name</h3>

                            <div className="mt-1 inner-input block w-full rounded-lg border-0 bg-white-100  py-2.5 text-black  px-3 sm:text-sm sm:leading-6">
                              {formData.bookingPackage}
                            </div>
                          </div>
                        </div>

                        {/* Price Details */}
                        <div className="bookingDetails">
                          <h2>Price Details</h2>

                          <div className="insideDetails">
                            <h3>Actual Per Person</h3>

                            <div className="mt-1 inner-input block w-full rounded-lg border-0 bg-white-100  py-2.5 text-black  px-3 sm:text-sm sm:leading-6">
                              {bookingPerPersonCostWithoutDiscount}
                            </div>
                          </div>

                          <div className="insideDetails">
                            <h3>Discounted Per Person</h3>

                            <div className="mt-1 inner-input block w-full rounded-lg border-0 bg-white-100  py-2.5 text-black  px-3 sm:text-sm sm:leading-6">
                              {bookingPerPersonCost}
                            </div>
                          </div>

                          <div className="insideDetails">
                            <h3>Total Discount</h3>

                            <div className="mt-1 inner-input block w-full rounded-lg border-0 bg-white-100  py-2.5 text-black  px-3 sm:text-sm sm:leading-6">
                              {bookingDiscountedAmount}
                            </div>
                          </div>

                          <div className="insideDetails">
                            <h3>Total</h3>

                            <div className="mt-1 inner-input block w-full rounded-lg border-0 bg-white-100  py-2.5 text-black  px-3 sm:text-sm sm:leading-6">
                              {bookingTotalCost}
                            </div>
                          </div>
                        </div>

                        {/* Price Details */}
                        <div className="bookingDetails">
                          <h2>Invoice details</h2>

                          <div className="insideDetails">
                            <h3>Company/ Person name</h3>

                            <div className="mt-1 inner-input block w-full rounded-lg border-0 bg-white-100  py-2.5 text-black  px-3 sm:text-sm sm:leading-6">
                              {formData.bookerCompanyName}
                            </div>
                          </div>

                          <div className="insideDetails">
                            <h3>VAT number</h3>

                            <div className="mt-1 inner-input block w-full rounded-lg border-0 bg-white-100  py-2.5 text-black  px-3 sm:text-sm sm:leading-6">
                              {formData.bookerVATNumber}
                            </div>
                          </div>

                          <div className="insideDetails">
                            <h3>Street address</h3>

                            <div className="mt-1 inner-input block w-full rounded-lg border-0 bg-white-100  py-2.5 text-black  px-3 sm:text-sm sm:leading-6">
                              {formData.bookerAddress}
                            </div>
                          </div>

                          <div className="insideDetails">
                            <h3>City</h3>

                            <div className="mt-1 inner-input block w-full rounded-lg border-0 bg-white-100  py-2.5 text-black  px-3 sm:text-sm sm:leading-6">
                              {formData.bookerCity}
                            </div>
                          </div>

                          <div className="insideDetails">
                            <h3>Postal Code</h3>

                            <div className="mt-1 inner-input block w-full rounded-lg border-0 bg-white-100  py-2.5 text-black  px-3 sm:text-sm sm:leading-6">
                              {formData.bookerPostalCode}
                            </div>
                          </div>
                          <div className="insideDetails">
                            <h3>Country</h3>

                            <div className="mt-1 inner-input block w-full rounded-lg border-0 bg-white-100  py-2.5 text-black  px-3 sm:text-sm sm:leading-6">
                              {formData.bookerCountry}
                            </div>
                          </div>
                          <div className="insideDetails">
                            <h3>State/ Province</h3>

                            <div className="mt-1 inner-input block w-full rounded-lg border-0 bg-white-100  py-2.5 text-black  px-3 sm:text-sm sm:leading-6">
                              {formData.bookerState}
                            </div>
                          </div>
                        </div>

                        {booking[0]?.foodItemSelected && (
                          <>
                            {/* {/ Food menu /} */}
                            {booking[0]?.foodItemSelected.food &&
                              Object.keys(booking[0]?.foodItemSelected.food)
                                .length > 0 && (
                                <div className="bookingDetails">
                                  <h2>Food menu</h2>
                                  <div className="w-full rounded-2xl bg-white">
                                    {renderMenu(
                                      booking[0]?.foodItemSelected.food,
                                      "F"
                                    )}
                                  </div>
                                </div>
                              )}

                            {/* {/ Beverage menu /} */}
                            {booking[0]?.foodItemSelected.beverage &&
                              Object.keys(booking[0]?.foodItemSelected.beverage)
                                .length > 0 && (
                                <div className="bookingDetails">
                                  <h2>Beverage menu</h2>
                                  <div className="w-full rounded-2xl bg-white">
                                    {renderMenu(
                                      booking[0]?.foodItemSelected.beverage,
                                      "B"
                                    )}
                                  </div>
                                </div>
                              )}
                          </>
                        )}
                      </div>

                      {/* Complimentary notes  */}
                      <div className="bookingDetails">
                        <h2>Complimentary Notes</h2>
                        <div
                          className="insideDetails"
                          style={{ width: "100%" }}
                        >
                          <div className="mt-1" style={{ width: "100%" }}>
                            <textarea
                              name="complimentaryNotes"
                              id="complimentaryNotes"
                              value={complimentaryNotes}
                              onChange={handleNotesChange}
                              readOnly={
                                (recordStatus !== 1 && recordStatus !== 4) ||
                                isPastDate(formData.bookingDate)
                              }
                              className={`date-input block w-full rounded-lg border-0 bg-gray-100 py-2.5 px-3 text-black shadow-sm ring-1 ring-inset ring-white/10 ${
                                recordStatus !== 1 ||
                                isPastDate(formData.bookingDate)
                                  ? "pointer-events-none focus:ring-transparent"
                                  : "focus:ring-groupleBlue"
                              } focus:ring-1 cursor-pointer focus:bg-white sm:text-sm sm:leading-6`}
                            />
                          </div>
                        </div>
                      </div>

                      {/* Invited Guests */}
                      {booking[0]?.invitee_list?.invitee_list &&
                        booking[0].invitee_list.invitee_list.length > 0 && (
                          <div className="bookingDetails">
                            {/* <h2>Guest List</h2> */}
                            <div className="w-full rounded-2xl bg-white">
                              <Disclosure>
                                {({ open }) => (
                                  <>
                                    <Disclosure.Button
                                      className={`foodMenuDropdownButton flex px-3 py-3 w-full justify-between ounded-xl bg-white ${
                                        open ? "" : ""
                                      }`}
                                    >
                                      <span className="text-base font-medium text-black">
                                        Guest List
                                      </span>
                                      {/* <span className="text-base font-medium text-black">
                                        Phone Number
                                      </span> */}
                                      <ChevronDownIcon
                                        className={`${
                                          open ? "rotate-180 transform" : ""
                                        } h-5 w-5 text-black-500`}
                                      />
                                    </Disclosure.Button>

                                    <Disclosure.Panel className="dropdownMenuWrapperInvitee pl-2 pr-4 py-4">
                                      <div className="flex justify-between py-4 pr-4 pl-2">
                                        <span className="text-base font-medium text-black">
                                          Name
                                        </span>
                                        <span className="text-base font-medium text-black">
                                          Phone Number
                                        </span>
                                      </div>

                                      {booking[0].invitee_list.invitee_list.map(
                                        (invitee) => (
                                          <div
                                            key={invitee.user_invitee_id}
                                            className="flex justify-between menuItemNameWrapper bg-white hover:bg-[#F0F1F3] pl-2 pr-4 py-2 rounded-lg transition-colors"
                                          >
                                            <div className="font-normal text-sm text-gray-900">
                                              {`${invitee.invitee_fname} ${invitee.invitee_lname}`}
                                            </div>
                                            <div className="font-normal text-sm text-gray-900 pr-3">
                                              {invitee.invitee_mobile}
                                            </div>
                                          </div>
                                        )
                                      )}
                                    </Disclosure.Panel>
                                  </>
                                )}
                              </Disclosure>
                            </div>
                          </div>
                        )}
                    </div>

                    {/* footer */}
                    {currentSection === "bookings" &&
                      booking[0].record_status === 0 && (
                        <div className="flex flex-shrink-0 justify-center gap-4 px-4 py-4">
                          {cancelClicked && (
                            <div>
                              <div className="flex justify-center">
                                <div
                                  style={{
                                    color: "#B15C52",
                                    fontSize: "1rem",
                                    fontStyle: "normal",
                                    fontWeight: "500",
                                  }}
                                  className="flex gap-2"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="18"
                                    viewBox="0 0 18 18"
                                    fill="none"
                                  >
                                    <path
                                      d="M8.9375 0C7.16983 0 5.44186 0.524175 3.9721 1.50624C2.50233 2.48831 1.35679 3.88415 0.680331 5.51727C0.00387248 7.15038 -0.17312 8.94742 0.171736 10.6811C0.516591 12.4148 1.36781 14.0073 2.61774 15.2573C3.86767 16.5072 5.46018 17.3584 7.19388 17.7033C8.92759 18.0481 10.7246 17.8711 12.3577 17.1947C13.9909 16.5182 15.3867 15.3727 16.3688 13.9029C17.3508 12.4331 17.875 10.7052 17.875 8.9375C17.8725 6.5679 16.9301 4.29606 15.2545 2.6205C13.5789 0.944933 11.3071 0.00250234 8.9375 0ZM8.25 4.8125C8.25 4.63016 8.32244 4.4553 8.45137 4.32636C8.5803 4.19743 8.75517 4.125 8.9375 4.125C9.11984 4.125 9.29471 4.19743 9.42364 4.32636C9.55257 4.4553 9.625 4.63016 9.625 4.8125V9.625C9.625 9.80734 9.55257 9.98221 9.42364 10.1111C9.29471 10.2401 9.11984 10.3125 8.9375 10.3125C8.75517 10.3125 8.5803 10.2401 8.45137 10.1111C8.32244 9.98221 8.25 9.80734 8.25 9.625V4.8125ZM8.9375 13.75C8.73354 13.75 8.53416 13.6895 8.36457 13.5762C8.19498 13.4629 8.06281 13.3018 7.98475 13.1134C7.9067 12.925 7.88628 12.7176 7.92607 12.5176C7.96586 12.3175 8.06408 12.1338 8.2083 11.9895C8.35252 11.8453 8.53627 11.7471 8.73632 11.7073C8.93636 11.6675 9.14371 11.6879 9.33215 11.766C9.52058 11.8441 9.68164 11.9762 9.79496 12.1458C9.90827 12.3154 9.96875 12.5148 9.96875 12.7188C9.96875 12.9923 9.8601 13.2546 9.66671 13.448C9.47331 13.6413 9.21101 13.75 8.9375 13.75Z"
                                      fill="#B15C52"
                                    />
                                  </svg>
                                  <div>
                                    Are you sure you want to cancel this
                                    reservation? This action cannot be undone.
                                  </div>
                                </div>
                              </div>
                              <div className="flex justify-center gap-4  py-2">
                                <button
                                  type="button"
                                  style={{
                                    color: "#EF4722",
                                    border: "1px solid #EF4722",
                                  }}
                                  className="w-3/5 inline-flex justify-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                                  onClick={handleButtonClick}
                                >
                                  Cancel
                                </button>
                                <button
                                  type="button"
                                  style={{ background: "#009600" }}
                                  className="w-3/5 inline-flex justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
                                  onClick={handleButtonClick}
                                >
                                  Approve
                                </button>
                              </div>
                            </div>
                          )}
                          {!cancelClicked && (
                            <>
                              <button
                                type="button"
                                style={{
                                  color: "#EF4722",
                                  border: "1px solid #EF4722",
                                }}
                                className="w-3/5 inline-flex justify-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                                onClick={handleButtonClick}
                              >
                                Cancel
                              </button>
                              <button
                                type="button"
                                style={{ background: "#009600" }}
                                className="w-3/5 inline-flex justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
                                onClick={handleButtonClick}
                              >
                                Approve
                              </button>
                            </>
                          )}
                        </div>
                      )}
                    {(currentSection === "bookings" &&
                      booking[0].record_status === 1) ||
                    (currentSection === "bookings" &&
                      booking[0].record_status === 5) ||
                    booking[0].record_status === 4 ||
                    currentSection === "dashboard" ? (
                      <div>
                        {isDirty && errorMessage === "" && (
                          <div className="flex flex-shrink-0 justify-center px-4 py-4">
                            <button
                              type="button"
                              className="w-full inline-flex justify-center rounded-md bg-groupleBlue px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
                              onClick={handleButtonClick}
                            >
                              Save Reservation
                            </button>
                          </div>
                        )}
                      </div>
                    ) : null}
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
